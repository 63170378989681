import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import largeBannerUrl from './../../assets/images/InnerPeace.png';
import Button from "@mui/material/Button";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import InspirationGallery from './InspirationGallery';

export default function InspirationSection() {
    const styles = {
        mainContainer:{
            width:'100%',
            padding:{xs:'100px 0px',sm:'100px 0px',md:'30px'},
            backgroundColor:'#FCF8F3',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
        },
        contentContainer: {
            width:{xs:'90%',sm:'90%',md:'100%'},
            height:'100%',
            display:'flex',
            flexDirection:{xs:'column',sm:'column',md:'row'},
            alignItems:{xs:'center',sm:'center',md:'stretch'},
            justifyContent:'space-between',
        },
        maintextContainer:{
            width:{xs:'100%',sm:'100%',md:'30%'},
            display:'flex',
            flexDirection:'column',
            alignItems:{xs:'center',sm:'center',md:'start'},
            justifyContent:'center',
        },
        title: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '40px',
            lineHeight: '120%',
            color: '#3A3A3A',
            textAlign:{xs:'center',sm:'center',md:'left'},
            marginBottom:'10px',
        },
        caption: {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '150%',
            color: '#616161',
            textAlign:{xs:'center',sm:'center',md:'left'},
            marginBottom:'25px',
        },
        button: {
            backgroundColor:'#B88E2F',
            color:'#fff',
            fontStyle: 'normal',
            fontWeight:' 600',
            fontSize: '13px',
            lineHeight: '150%',
            padding:'12px 36px',
            marginBottom:{xs:'100px',sm:'100px',md:'0'},
        },
        largeBannerContainer:{
            display: 'grid',
            gridTemplaterows:{xs:'repeat(5,1fr)',sm:'repeat(5,1fr)',md:'repeat(4,1fr)'},
            width:{xs:'100%',sm:'100%',md:'30%'},
            height:{xs:'100vh',sm:'80vh',md:'auto'},
            backgroundImage: `url(${largeBannerUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat:'no-repeat',
            marginBottom:{xs:'0',sm:'100px',md:'0'},
    },
        largeBannerContents:{
            gridRow:{xs:'5/6',sm:'5/6',md:'4/5'},
            margin:{xs:'0',sm:'0',md:'0 0 20px 20px'},
            display:'flex',
            flexDirection:{xs:'column',sm:'column',md:'row'},
            alignItems:'end'
        },
        largeBannerTextContainer:{
            padding:'32px',
            backgroundColor:'#ffffff',
            opacity:'0.72',
        },
        arrowBtn:{
            backgroundColor: '#B88E2F',
            color:'#fff',
            padding:'10px 10px',
            cursor:'pointer',
            '&:hover': {
                opacity:'0.8'
            }
        },
        galleryContainer: {
            width:{xs:'100%',sm:'100%',md:'30%'},
            marginTop:{xs:'100px',sm:'0',md:'0'},
            height:'100%',
        }
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Box sx = {styles.contentContainer}>
                <Box sx = {styles.maintextContainer}>
                    <Typography sx = {styles.title}>50+ Beautiful rooms inspiration</Typography>
                    <Typography sx = {styles.caption}>Our designer already made a lot of beautiful prototipe of rooms that inspire you</Typography>
                    <Button sx = {styles.button}>Explore More</Button>
                </Box>
                <Box sx={styles.largeBannerContainer}>
                    <Box sx = {styles.largeBannerContents} component={"div"}>
                        <Box sx = {styles.largeBannerTextContainer}>
                            <Typography>01---Bed Room</Typography>
                            <Typography>Inner Peace</Typography>
                        </Box>
                        <ArrowRightAltIcon sx = {styles.arrowBtn}/>
                    </Box>
                </Box>
                <Box sx = {styles.galleryContainer}>
                    <InspirationGallery />
                </Box>
            </Box>
        </Box>
    )
}