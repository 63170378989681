import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CategoriesGallery from "./CategoriesGallery";

export default function CategoriesSection() {
    const styles = {
        mainContainer: {
            width:'100%',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'space-between',
            // marginTop: { xs: '200px', sm: '200px', md: '0' }
        },
        title: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: {xs:'22px',sm:'24px',md:'32px'},
            lineHeight: '48px',
            color: '#333333',
            marginTop:'60px'
        },
        caption:{
            width:{xs:'50%',sm:'100%',md:'100%'},
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: {xs:'14px',sm:'24px',md:'20px'},
            lineHeight: '30px',
            textAlign: 'center',
            color: '#666666',
            marginBottom:{xs:'30px',sm:'10px',md:'63px'}
        }
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Typography sx = {styles.title}>Browse The Range</Typography>
            <Typography sx = {styles.caption}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
            <CategoriesGallery />
        </Box>
    )
}