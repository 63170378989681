import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ProductsGallery from "./ProductsGallery";

export default function ProductsSection() {
    const styles = {
        mainContainer: {
            marginTop:'60px',
            marginBottom:'60px',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            gap:'30px',
        },
        title: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: {xs:'22px',sm:'24px',md:'32px'},
            lineHeight: '120%',
            textAlign: 'center',
            color: '#3A3A3A',
            marginBottom:'32px'
        },
        button:{
            color:'#B88E2F',
            border:'1px solid #B88E2F',
            padding:'12px 82px',

            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '150%',
        },
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Typography sx = {styles.title}>Our Products</Typography>
            <ProductsGallery galleryLen = {8}/>
            <Button variant="outlined" sx = {styles.button}>Show More</Button>
        </Box>

    )
}


