import Box from '@mui/material/Box';

import heroImageUrl from './../assets/images/homePageHero.png';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function HomePageHeroBanner() {
    const styles = {
        mainContainer: {
            position:'relative',
            width:'100%',
            height:{xs:'100vh',sm:'50vh',md:'80vh'},
            backgroundImage:`url(${heroImageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition:'center',
            backgroundRepeat:'no-repeat',
            marginBottom: { xs: '100%', sm: '50%', md: '0' },
            // marginBottom: { xs: '100px', sm: '200px', md: '0' },
        },
        heroTextContainer: {
            display:{xs:'flex',sm:'flex',md:'flex'},
            flexDirection:'column',
            alignItems:'start',
            justifyContent:'space-between',
            position:'absolute',
            top:{xs:'100%',sm:'100%',md:'25%'},
            right:{xs:'auto',sm:'auto',md:'58px'},
            left:{xs:'0',sm:'auto',md:'auto'},
            width:{xs:'auto',sm:'auto',md:'35%'},
            padding:'62px 39px',
            backgroundColor:'#FFF3E3',
        },
        heroText1: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '16px',
            letterSpacing: '3px',
            color: '#333333',
        },
        heroText2: {
            width:{xs:'100%',sm:'70%',md:'70%'},
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: {xs:'20px',sm:'35px',md:'35px'},
            lineHeight: '40px',
            color: '#B88E2F',
            marginTop:'20px',
        },
        heroText3: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '24px',
            color: '#333333',
            marginTop:'20px',
        },
        button: {
            backgroundColor:'#B88E2F',
            color:'#fff',
            padding:{xs:'10px 30px',sm:'14px 50px',md:'14px 50px'},
            marginTop:'30px',
            borderRadius:'0',
            fontWeight:'bold',
            fontSize:{xs:'10px',sm:'14px',md:'14px'}
        }
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Box id="hero-text" sx = {styles.heroTextContainer}>
                <Typography sx = {styles.heroText1}>New Arrival</Typography>
                <Typography sx = {styles.heroText2}>Discover Our New Collection</Typography>
                <Typography sx = {styles.heroText3}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis.</Typography>
                <Button sx = {styles.button}>BUY Now</Button>
            </Box>
        </Box>

    )
}