import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: '1',
    imgPath:
      require('./../../assets/images/Inspiration01.png'),
  },
  {
    label: '2',
    imgPath:
      require('./../../assets/images/Inspiration02.png'),
  },
  {
    label: '3',
    imgPath:
      require('./../../assets/images/Inspiration01.png'),
  },
  {
    label: '4',
    imgPath:
      require('./../../assets/images/Inspiration02.png'),
  },
];

function InspirationGallery() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };


  const styles = {
    mainContainer: { 
        position:'relative',
        width:'100%',
        height:'100%',
        marginLeft:{xs:'0px',sm:'0px',md:'20px'},
      },
    contentContainer: {
        width:'100%',
        height:'100%',
        margin:'0',
    },
    paper: {
        display: 'flex',
        alignItems: 'center',        
        pl: 2,
        bgcolor: 'background.default',  
    },
    mobileStepper: {
        bgcolor:'transparent',        
        '& .MuiMobileStepper-dots': {
            gap:'10px',
        },
        '& .MuiMobileStepper-dotActive': {
            bgcolor:'#B88E2F',
        },
        '& .MuiMobileStepper-dots': {
          mx:{xs:'auto',sm:'auto',md:'0'},
        },
    },
    imageBox: {
        // height: '500px',
        display: 'block',
        // maxWidth: 400,
        overflow: 'hidden',
        width: '100%',
        marginBottom:'20px',
        // border:'1px solid red',
        // mx:{xs:'0',sm:'0',md:'auto'},
    },
    btnsContainer: {
        position:'absolute',
        top:'50%',
        transform:'translateY(-50%)',
        left:'0',
        width:'100%',
        height:'100px',
        zIndex:'200',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
    },
    imgBackBtn: {
        borderRadius:'50%',
        backgroundColor:'#fff',
        width:'48px',
        height:'48px',
        visibility: activeStep !== 0 ? 'visible' : 'hidden',
    },
    imgNextBtn: {
        borderRadius:'50%',
        backgroundColor:'#fff',
        width:'48px',
        height:'48px',
        visibility: activeStep !== maxSteps - 1 ? 'visible' : 'hidden',
    },
    arrowIconStyle: {
        fontSize:'30px',
        color:'#B88E2F',
    },

  }

  return (
    <Box sx={styles.mainContainer}>
        <Box sx = {styles.btnsContainer}>
            <Button disabled = {activeStep === 0} sx = {styles.imgBackBtn} onClick ={handleBack}><KeyboardArrowLeft sx = {styles.arrowIconStyle}/></Button>
            <Button disabled = {activeStep === maxSteps - 1} sx = {styles.imgNextBtn} onClick ={handleNext}><KeyboardArrowRight sx = {styles.arrowIconStyle}/></Button>
        </Box>
        <Box sx={styles.contentContainer}>
        <Paper
            square
            elevation={0}
            sx={styles.paper}
        >
            <Typography>{images[activeStep].label}</Typography>
        </Paper>
        <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
        >
            {images.map((step, index) => (
            <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                <Box
                    component="img"
                    sx={styles.imageBox}
                    src={step.imgPath}
                    alt={step.label}
                />
                ) : null}
            </div>
            ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx = {styles.mobileStepper}
        />
        </Box>
    </Box>
  );
}

export default InspirationGallery;
