import { useState,useEffect } from "react"
import { useMediaQuery, useTheme } from '@mui/material';

import Fab from '@mui/material/Fab';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function BackToTop() {
    const [isScrolling,setIsScrolling] = useState(false);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('sm'));
    const isSM = useMediaQuery(theme.breakpoints.between('sm','md'));
    const isMD = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        window.addEventListener('scroll',handleScrolling);
        return () => {
            window.removeEventListener('scroll',handleScrolling);
        }
    })
    const handleScrolling = () => {
        if (window.scrollY >= window.innerHeight / 2) {
            setIsScrolling(true);
        } else {
            setIsScrolling(false);
        }
    }
    const scrollTop = () => {
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    }

    return (
        <>
            {
                isScrolling &&
                    <Fab
                        sx = {{
                            position:'fixed',
                            right: isMD ? '200px' : isSM ? '50%' : isXS ? '50%' : '50%',
                            transform: !isMD ? 'translateX(50%)' : 'none',
                            bottom:'30px',
                        }} 
                        onClick={() => {scrollTop()}}  
                    >
                        <ExpandLessIcon />
                    </Fab>
            }
        </>        
    )
}