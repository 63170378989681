const ProductsData = [
    {
      img: require('./../assets/images/Syltherine.png'),
      title : 'Syltherine',
      caption : 'Stylish cafe chair',
      price : 'Rp 2.500.000',
      priceOld : 'Rp 3.500.000',
      idTxt : '-30%',
      idColor:'#E97171',
    },
    {
      img: require('./../assets/images/Leviosa.png'),
      title : 'Leviosa',
      caption : 'Stylish cafe chair',
      price : 'Rp 2.500.000',
      priceOld : '',
      idTxt : '-30%',
      idColor:'#E97171',
    },
    {
      img: require('./../assets/images/Lolito.png'),
      title : 'Lolito',
      caption : 'Luxury big sofa',
      price : 'Rp 7.000.000',
      priceOld : 'Rp 14.000.000',
      idTxt : '-50%',
      idColor:'#E97171',
    },
    {
      img: require('./../assets/images/Respira.png'),
      title : 'Respira',
      caption : 'Outdoor bar table and stool',
      price : 'Rp 500.000',
      priceOld : '',
      idTxt : 'NEW',
      idColor:'#2EC1AC',
    },
    {
      img: require('./../assets/images/Grifo.png'),
      title : 'Grifo',
      caption : 'Night lamp',
      price : 'Rp 1.500.000',
      priceOld : '',
      idTxt : '',
      idColor:'transparent',
    },
    {
      img: require('./../assets/images/Muggo.png'),
      title : 'Muggo',
      caption : 'Small mug',
      price : 'Rp 150.000',
      priceOld : '',
      idTxt : 'NEW',
      idColor:'#2EC1AC',
    },
    {
      img: require('./../assets/images/Pingky.png'),
      title : 'Pingky',
      caption : 'Cute bed set',
      price : 'Rp 7.000.000',
      priceOld : 'Rp 14.000.000',
      idTxt : '-50%',
      idColor:'#E97171',
    },
    {
      img: require('./../assets/images/Potty.png'),
      title : 'Potty',
      caption : 'Minimalist flower pot',
      price : 'Rp 500.000',
      priceOld : '',
      idTxt : 'NEW',
      idColor:'#2EC1AC',
    },
  ];

  export default ProductsData;