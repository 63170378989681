import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import FooterLogo from './FooterLogo';
import FooterMenu from './FooterMenu';
import FooterHelpMenu from './FooterHelpMenu';
import FooterNewsLetter from './FooterNewsLetter';
import Copyright from './Copyright';

export default function Footer() {
    const styles = {
        mainContainer: {
            display:'flex',
            flexDirection:'column',
            alignItems:'start',
            borderTop:'1px solid #D9D9D9',
            marginBottom:'300px',
            padding:'50px 0',
        },
        contentContainer: {
            width:'100%',
            display:'flex',
            flexDirection:{xs:'column',sm:'column',md:'row'},
            alignItems:{xs:'center',sm:'center',md:'start'},
            justifyContent:'space-between',
            borderBottom:'1px solid #D9D9D9',
            gap:'40px',
            marginBottom:'30px',
            paddingBottom:'60px',
        },
        logoContainer: {
            display:'flex',
            flexDirection:'column',
            alignItems:{xs:'center',sm:'center',md:'start'},
            gap:'30px',
            width:{xs:'80%',sm:'50%',md:'30%'},                        
        },
        caption: {
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#9F9F9F',
            textAlign:{xs:'center',sm:'center',md:'justify'}
        }
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Box sx = {styles.contentContainer}>
                <Box sx = {styles.logoContainer}>
                    <FooterLogo />
                    <Typography sx = {styles.caption}>400 University Drive Suite 200 Coral Gables, FL 33134 USA</Typography>
                </Box>
                <FooterMenu />
                <FooterHelpMenu />
                <FooterNewsLetter />
            </Box>
            <Copyright />
        </Box>

    )
}