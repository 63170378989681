import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


export default function FooterNewsLetter() {
    const styles = {
        mainContainer: {
            display:'flex',
            flexDirection:'column',
        },
        title: {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#9F9F9F',
        },
        inputContainer: {
            display:'flex',
            flexDirection:'row',
            alignItems:'end',
            gap:'20px'
        },
        input: {
            '& .MuiInputBase-input': {
                '::placeholder':{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#9F9F9F',
                }
            },
        },
        button: {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '21px',
            color: '#000000',
            paddingBottom:'0',
            borderBottom:'1px solid black',
            borderRadius:'0',
        }
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Typography sx = {styles.title}>Newsletter</Typography>
            <Box sx={styles.inputContainer}>
                <TextField
                    variant = "standard"
                    label="Email"                
                    sx = {styles.input}
                    placeholder="Enter Your Email Address"
                />
                <Button sx = {styles.button}>Subscribe</Button>
            </Box>
        </Box>

    )
}