import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import logoImg from './../../assets/logo/Logo.png'
import mainMenuItems from '../../dataSources/mainMenuItems.js';
import IconsBar from './IconsBar.js';
import MobileMenuDrawer from './MobileMenuDrawer.js';
import zIndex from '@mui/material/styles/zIndex.js';


function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isScrolling,setIsScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll',handleScrolling);
    return () => {
      window.removeEventListener('scroll',handleScrolling);
    }
  })

  const handleScrolling = () => {
    if (window.scrollY >= window.innerHeight / 5) {
      setIsScrolling(true);
  } else {
      setIsScrolling(false);
  }
}

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const styles = {
    mainContainer: {
      width:'100vw',
      position: (isScrolling) ? 'fixed' : 'none',
      top: (isScrolling) ? '0' : 'none',
      zIndex:'100',
    },
    appbar: {
        maxWidth:"lg",
        mx:'auto',
        height:'100px',
        backgroundColor:'#fff',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
    },
    logoContainer: {
        width:{xs:'100px',sm:'150px',md:'185px'},
    },
    logoImg: {
        width:'100%'
    },
    toolbar: {
        width:'100%',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        gap:'30px',
        // border:'5px solid green'
    },
    mainMenu: {     
        width:'30%',   
        // flexGrow: 1,
        display: { xs: 'none', md: 'flex' },
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        // border:'1px solid red',
    },
    iconsBar: {
        // border:'1px solid red'
    }
  }
  return (
    <Box sx = {styles.mainContainer}>
      <AppBar position="static" sx = {styles.appbar}>
        <Container maxWidth="xl">
          <Toolbar sx = {styles.toolbar} disableGutters>
            <Box id = 'logo-contaienr' sx = {styles.logoContainer}>
              <img src = {logoImg} alt="logo" style = {styles.logoImg}/>
            </Box>
            <Box sx={styles.mainMenu}>
              {mainMenuItems.map((item) => (
                <Button
                  key={item}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: '#000', display: 'block' }}
                >
                  {item}
                </Button>
              ))}
            </Box>
            <Box sx = {{display:{xs:'none',sm:'none',md:'block'},width:'15%'}}>
              <IconsBar />
            </Box>
            <Box sx = {{display:{xs:'block',sm:'block',md:'none'}}}>
              <MobileMenuDrawer />
            </Box>

          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}
export default NavBar;