import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import FavoriteIcon from '@mui/icons-material/Favorite';


export default function ProductCard({img,title,caption,price,priceOld,idTxt,idColor}) {
  const [hovered,setHovered] = useState(false);
  const styles = {
    mainContainer: {
        // width:'100%',
        // height:'100%',
        width:'300px',
        height:'480px',
        position:'relative',
        padding:'0',
        mx:'auto'
    },
    idTxtContainer: {
        position:'absolute',
        top:'24px',
        right:'24px',
        width:'48px',
        height:'48px',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        borderRadius:'50%',
        color:'#000',
        zIndex:'100',
        backgroundColor:idColor,
        color:'#fff'
    },
    overlay: {
        display:hovered ? 'block' : 'none',
        backgroundColor:'#3A3A3A72',
        position:'absolute',
        width:'100%',
        height:'100%',
        zIndex:'200',
    },
    overlayContent:{
        width:'100%',
        height:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
    },
    addToCartBtn: {
        backgroundColor:'#fff',
        color:'#B88E2F',
        padding:'12px 60px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '150%',
        textTransform:'capitalize',
        '&:hover': {
            backgroundColor:'#B88E2F',
            color:'#fff'
        }
    },
    actionButtonsContainer:{
        width:'90%',
        backgroundColor:'transparent',
        marginTop:'24px',
    },
    actionButton:{
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        lineHeight: '100%',
        color: '#FFFFFF',
        '&:hover': {
            color:'#B88E2F'
        }
    },
    card:{
        // position:'absolute',
        // width:'300px',
        width:'100%',

    },
    cardContent: {
        margin:'16px',
    },
    title: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '120%',
        color: '#3A3A3A',
        marginTop:'16px'
    },
    caption:{
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '150%',
        color: '#898989',
        marginTop:'8px'
    },
    pricesContainer: {
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        marginTop:'8px',
        
    },
    price: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '150%',
        color: '#3A3A3A',
    },
    oldPrice: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '150%',
        textDecorationLine: 'line-through',
        color: '#B0B0B0',
    }
  }
  return (
    <Box 
        onMouseEnter = {() => {setHovered(true)}}
        onMouseLeave = {() => {setHovered(false)}}
        sx = {styles.mainContainer}
    >
        <Box sx = {styles.idTxtContainer}>{idTxt}</Box>
        <Box sx = {styles.overlay}>
            <Box sx = {styles.overlayContent}>
                <Button variant="contained" sx = {styles.addToCartBtn}>Add to cart</Button>
                <Box sx = {styles.actionButtonsContainer}>
                    <Button startIcon = {<ShareIcon/>} sx = {styles.actionButton}>Share</Button>
                    <Button startIcon = {<SyncAltIcon/>} sx = {styles.actionButton}>Compare</Button>
                    <Button startIcon = {<FavoriteIcon/>} sx = {styles.actionButton}>Like</Button>
                </Box>
            </Box>
        </Box>
        <Card sx={styles.card}>
            <CardMedia
                component="img"
                height="301px"
                image= {img}
                alt="green iguana"
            />
            <CardContent sx = {styles.cardContent}>
                <Typography gutterBottom  component="div" sx = {styles.title}>
                {title}
                </Typography>
                <Typography   sx = {styles.caption}>
                {caption}
                </Typography>
                <Box sx = {styles.pricesContainer}>
                    <Typography   sx = {styles.price}>
                        {price}
                    </Typography>
                    <Typography   sx = {styles.oldPrice}>
                        {priceOld}
                    </Typography>            
                </Box>
            </CardContent>
        </Card>
    </Box>
  );
}
