import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import mainMenuItems from '../../dataSources/mainMenuItems';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import InfoIcon from '@mui/icons-material/Info';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export default function MobileMenuDrawer() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const mainMenuIcons = [
    <HomeIcon />,
    <StorefrontIcon />,
    <InfoIcon />,
    <PhoneIcon />
  ]
  const minorMenuIcons = [
    <PersonOutlineIcon />,
    <SearchIcon />,
    <FavoriteBorderIcon />,
    <ShoppingCartIcon />
  ]
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {mainMenuItems.map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                 {mainMenuIcons[index]}
              </ListItemIcon>
              <ListItemText primary={text} sx = {{'& .MuiTypography-root':{fontSize:{xs:'16px',sm:'24px'}}}}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['Profile', 'Search', 'Favourite','Cart'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {minorMenuIcons[index]}
              </ListItemIcon>
              <ListItemText primary={text} sx = {{'& .MuiTypography-root':{fontSize:{xs:'16px',sm:'24px'}}}} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon sx = {{fontSize:{xs:'30px',sm:'40px',md:'40px'},color:'#000'}} />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor = 'right'>
        {DrawerList}
      </Drawer>
    </div>
  );
}
