import { BrowserRouter, Routes, Route } from "react-router-dom";


import NavBar from './components/NavBar/NavBar';
import HomePage from "./pages/HomePage";

function App() {
  const styles = {
    app: {
      margin:'0',
      padding:'0'
    }
  }
  return (
    <div className="App" style = {styles.app}>
      <BrowserRouter>
      <NavBar />
      <Routes>
        <Route exact path = "/" element = {<HomePage />}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
