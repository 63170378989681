import Box from '@mui/material/Box';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export default function IconsBar() {
    const styles = {
        mainContainer: {
            width:'100%',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
            color:'#000',
        }
    }
    return (
        <Box sx = {styles.mainContainer}>
            <PersonOutlineIcon />
            <SearchIcon />
            <FavoriteBorderIcon />
            <ShoppingCartIcon />
        </Box>        
    )
}