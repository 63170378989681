import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FuniroFurnitureGallery from './FuniroFurnitureGallery';


export default function FuniroFurnitureSection() {
    const styles = {
        mainContainer: {
            marginTop:'100px',
            marginBottom:'100px',
            px:'10px',
            
        },
        caption: {
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '150%',
            textAlign: 'center',
            color: '#616161',
        },
        title:{
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '40px',
            lineHeight: '120%',
            textAlign: 'center',
            color: '#3A3A3A',
        },
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Typography sx = {styles.caption}>Share your setup with</Typography>
            <Typography sx = {styles.title}>#FuniroFurniture</Typography>
            <FuniroFurnitureGallery />
        </Box>

    )
}