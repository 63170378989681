const CategoriesData = [
    {
      img: require('./../assets/images/Dining.png'),
      title: 'Dining',
    },
    {
      img: require('./../assets/images/Living.png'),
      title: 'Living',
    },
    {
      img: require('./../assets/images/Bedroom.png'),
      title: 'Bedroom',
    },
  ];

  export default CategoriesData;