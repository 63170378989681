import Box from "@mui/material/Box";
import helpMenuItems from "../../dataSources/helpMenuItems";


import Typography from "@mui/material/Typography";
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
export default function FooterHelpMenu() {
    const styles = {
        mainContainer:{
            display:'flex',
            flexDirection:'column',
            alignItems:'start',
            padding:'0',
        },
        menuItemTxt: {
            textTransform:'capitalize',
        },
        title: {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#9F9F9F',
        },
        menuItemList:{
            display:'flex',
            flexDirection:'column',
            alignItems:'start',
            '& .MuiButtonBase-root':{
                padding:'16px 0'
            }
        },
    }
    return (
        <Box sx = {styles.mainContainer}>
            <Typography sx = {styles.title}>Links</Typography>
            <MenuList sx = {styles.menuItemList}>
                {
                    helpMenuItems.map((item,index) => {
                        return (
                            <MenuItem key = {index}>
                                <ListItemText sx = {styles.menuItemTxt}>{item}</ListItemText>
                            </MenuItem>
                        )
                    })
                }
            </MenuList>
        </Box>
    )
}