import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import ProductsData from '../../dataSources/ProductsData';
import ProductCard from './ProductCard';
import testImage from './../../assets/images/Syltherine.png'

const styles = {
  mainContainer: { 
    width: '100%', 
    height: '100%',
    flexGrow: 1
  },

}


export default function ProductsGallery({galleryLen}) {
  return (
    <Box sx={styles.mainContainer}>
      <Grid container spacing={{ xs: 10, md: 10 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {Array.from(Array(galleryLen)).map((_, index) => (
          <Grid xs={4} sm={4} md={3} key={index}>
            <ProductCard 
                img = {ProductsData[index].img} 
                title = {ProductsData[index].title} 
                caption = {ProductsData[index].caption}
                price = {ProductsData[index].price} 
                priceOld = {ProductsData[index].priceOld}
                idTxt = {ProductsData[index].idTxt}
                idColor={ProductsData[index].idColor}
            />
            {/* <Item>xs=2</Item> */}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
