import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function FooterLogo() {
    const styles = {
        txt: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '36px',
            color: '#000000',
        }
    }
    return (
        <Box>
            <Typography sx = {styles.txt}>Funiro</Typography>
        </Box>
    )
}