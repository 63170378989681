import Container from "@mui/material/Container"
import Box from "@mui/material/Box"
import HomePageHeroBanner from './../components/HomePageHeroBanner';
import CategoriesSection from '../components/CategoriesSection';
import ProductsSection from '../components/Products/ProductsSection';
import InspirationSection from "../components/Inspiration/InspirationSection";
import FuniroFurnitureSection from './../components/FuniroFurniture/FuniroFurnitureSection';
import Footer from './../components/Footer/Footer';
import BackToTop from "../components/BackToTop";

export default function HomePage() {
    const styles = {
        container: {
            padding:'0',
        },
        heroBannerContainer: {
            width:'100%',
            margin:'0',
        }
    }
    return (
        <Container maxWidth = "lg" sx = {styles.container} disableGutters>
            <HomePageHeroBanner />
            <CategoriesSection />
            <ProductsSection />
            <InspirationSection />
            <FuniroFurnitureSection />
            <Footer />
            <BackToTop />
        </Container>        
    )
}