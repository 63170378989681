import * as React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function FuniroFurnitureGallery() {
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('sm'));
    const isSM = useMediaQuery(theme.breakpoints.between('sm','md'));
    const isMD = useMediaQuery(theme.breakpoints.up('md'));
  const styles = {
    mainContainer: {
        width: '100%', 
    }
  }
  const cols = isXS ? 1 : isSM ? 2 : isMD ? 5 : 5;
  return (
    <ImageList sx={styles.mainContainer} variant="woven" cols={cols} gap={8}>
      {itemData.map((item,index) => (
        <ImageListItem key={item.img} >
          <img
            srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=161&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: require('./../../assets/images/FuniroFurnitureImages/1.png'),
    title: '1',
  },
  {
    img: require('./../../assets/images/FuniroFurnitureImages/2.png'),
    title: '2',
  },
  {
    img: require('./../../assets/images/FuniroFurnitureImages/3.png'),
    title: '3',
  },
  {
    img: require('./../../assets/images/FuniroFurnitureImages/4.png'),
    title: '4',
  },
  {
    img: require('./../../assets/images/FuniroFurnitureImages/5.png'),
    title: '5',
  },
  {
    img: require('./../../assets/images/FuniroFurnitureImages/6.png'),
    title: '6',
  },
  {
    img: require('./../../assets/images/FuniroFurnitureImages/7.png'),
    title: '7',
  },
  {
    img: require('./../../assets/images/FuniroFurnitureImages/8.png'),
    title: '8',
  },
  {
    img: require('./../../assets/images/FuniroFurnitureImages/9.png'),
    title: '9',
  },
  {
    img: require('./../../assets/images/FuniroFurnitureImages/10.png'),
    title: '10',
  },
];